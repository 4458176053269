/*
 * @Author: lirx
 * @Date: 2022-09-11 18:22:58
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-11 18:25:11
 */
import { attachPropertiesToComponent } from '../../../utils/attach-properties-to-component'
import { FlfLoading } from './loading'
import { show } from './show'

export default attachPropertiesToComponent(FlfLoading, {
  show,
})
