/*
 * @Author: lirx
 * @Date: 2022-09-10 18:05:57
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-14 11:24:02
 */
import { useTitle } from 'ahooks'
import { Button, Dialog, Form, Input } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { login, queryAllOrderProcess, queryisExpire } from '../../api/common'
import storage from '../../utils/storage'
import BackGround from '../component/background'

const LoginForm = styled(Form)`
  .adm-list-body {
    background-color: transparent;
    border: none;
  }
  .adm-form-footer {
    padding: 0;
  }
  width: 100%;
  padding: 32px;
  .adm-list-item {
  }
  position: fixed;
  top: 30%;
  border: none;
  .adm-list {
    border: none;
  }
`

const FormItem = styled(Form.Item)`
  border: none;
  .adm-list-item-content {
    border: none;
  }
  border-radius: 8px;
  margin-bottom: 10px;
`
const GoRegister = styled.div`
  font-size: 14px;
  margin-top: 12px;
  span {
    color: var(--adm-color-success);
  }
  color: #fff;
  text-align: right;
`
function Login() {
  useTitle('福临福-登录')
  const navigate = useNavigate()
  const onFinish = (values: any) => {
    login(values).then(res => {
      storage.set('username', res.username)
      storage.set('userRoleId', res.userRoleId)
      storage.set('userphone', values.phone)
      queryisExpire({})
      queryAllOrderProcess({}).then(res => {
        const orderMap = res.map((item: any) => {
          item.label = item.name
          item.value = item.id
          return item
        })
        storage.set('orderMap', orderMap)
        navigate('/flf/orderlist', { replace: true })
      })
      /**
       * 登录成功
       */
    })
  }

  return (
    <>
      <LoginForm
        layout='horizontal'
        onFinish={onFinish}
        onFinishFailed={v => {
          Dialog.alert({
            title: '温馨提示',
            content: v.errorFields[0].errors[0],
          })
        }}
        hasFeedback={false}
        requiredMarkStyle='none'
        footer={
          <>
            <Button block type='submit' color='success' size='large'>
              登录
            </Button>
            <GoRegister>
              <span
                onClick={() => {
                  navigate('/flf/reset')
                }}
              >
                修改密码
              </span>
            </GoRegister>
          </>
        }
      >
        <FormItem
          label='手机号'
          rules={[
            {
              required: true,
              message: '请输入11位手机号码',
              pattern: /^[1]{1}[0-9]{10}$/,
            },
          ]}
          name='phone'
        >
          <Input maxLength={11} placeholder='请输入手机号' clearable />
        </FormItem>
        <FormItem
          name='passWord'
          label='密码'
          rules={[{ required: true, message: '密码不能为空' }]}
        >
          <Input type='password' placeholder='请输入密码' clearable />
        </FormItem>
      </LoginForm>
      <BackGround />
    </>
  )
}
export default Login
