/*
 * @Author: lirx
 * @Date: 2022-09-10 14:01:28
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-12 10:47:59
 */
import { RouteObject } from 'react-router-dom'

import InputInfo from '../pages/input-info'
import Search from '../pages/search'
import Progress from '../pages/progress'
import Login from '../pages/login'
import Regist from '../pages/regist'
import OrderList from '../pages/order-list'
import EditOrderState from '../pages/edit-order-state'
import EditOrder from '../pages/edit-order'
import Reset from '../pages/reset'

const routes: RouteObject[] = [
  {
    path: '/',
    children: [
      {
        index: true,
        element: <Search />,
      },
      {
        path: '/flf/search',
        element: <Search />,
      },
      {
        path: '/flf/progress',
        element: <Progress />,
      },
      {
        path: '/flf/login',
        element: <Login />,
      },
      {
        path: '/flf/reset',
        element: <Reset />,
      },
      {
        path: '/flf/regist',
        element: <Regist />,
      },
      {
        path: '/flf/inputinfo',
        element: <InputInfo />,
      },
      {
        path: '/flf/orderlist',
        element: <OrderList />,
      },
      {
        path: '/flf/editorderstate',
        element: <EditOrderState />,
      },
      {
        path: '/flf/editorder',
        element: <EditOrder />,
      },
    ],
  },
]

export default routes
