/*
 * @Author: lirx
 * @Date: 2022-09-11 18:24:47
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-11 18:24:53
 */
export function attachPropertiesToComponent<C, P extends Record<string, any>>(
  component: C,
  properties: P
): C & P {
  const ret = component as any
  // eslint-disable-next-line no-restricted-syntax
  for (const key in properties) {
    // eslint-disable-next-line no-prototype-builtins
    if (properties.hasOwnProperty(key)) {
      ret[key] = properties[key]
    }
  }
  return ret
}
