/*
 * @Author: lirx
 * @Date: 2022-09-11 16:42:31
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-11 17:05:30
 */
import styled from 'styled-components'
import logo from './img/flf-logo.png'
import Bg from './img/bg.jpg'

const Wrap = styled.div`
  background-image: url(${Bg});
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: -1;
  position: fixed;
  display: flex;
  align-items: center;
  background-size: contain;
`

const Logo = styled.img`
  width: 200px;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
`

function BackGround() {
  return (
    <Wrap>
      <Logo src={logo} />
    </Wrap>
  )
}

export default BackGround
