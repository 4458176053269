/*
 * @Author: lirx
 * @Date: 2022-09-10 18:05:57
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-14 22:27:39
 */
import { useTitle } from 'ahooks'
import { Button, Dialog, Form, Input } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { setPassWord } from '../../api/common'
import BackGround from '../component/background'

const LoginForm = styled(Form)`
  .adm-list-body {
    background-color: transparent;
    border: none;
  }
  .adm-form-footer {
    padding: 0;
  }
  width: 100%;
  padding: 32px;
  .adm-list-item {
  }
  position: fixed;
  top: 30%;
  border: none;
  .adm-list {
    border: none;
  }
`

const FormItem = styled(Form.Item)`
  border: none;
  .adm-list-item-content {
    border: none;
  }
  border-radius: 8px;
  margin-bottom: 10px;
`

const GoRegister = styled.div`
  font-size: 14px;
  margin-top: 12px;
  span {
    color: var(--adm-color-success);
  }
  color: #fff;
  text-align: right;
`
function Login() {
  useTitle('福临福-密码修改')

  const navigate = useNavigate()
  const onFinish = (values: any) => {
    if (values.reNewPassword !== values.newPassword) {
      return Dialog.alert({
        title: '温馨提示',
        content: '新密码前后输入不同',
      })
    }
    setPassWord(values).then(() => {
      Dialog.alert({
        title: '温馨提示',
        content: '密码修改成功',
        onConfirm() {
          navigate('/flf/login', { replace: true })
        },
      })
    })
  }
  return (
    <>
      <LoginForm
        layout='horizontal'
        onFinish={onFinish}
        onFinishFailed={v => {
          Dialog.alert({
            title: '温馨提示',
            content: v.errorFields[0].errors[0],
          })
        }}
        hasFeedback={false}
        requiredMarkStyle='none'
        footer={
          <>
            <Button block type='submit' color='success' size='large'>
              修改密码
            </Button>
            <GoRegister>
              <span
                onClick={() => {
                  navigate('/flf/login', { replace: true })
                }}
              >
                去登录
              </span>
            </GoRegister>
          </>
        }
      >
        <FormItem
          label='手机号'
          rules={[
            {
              required: true,
              message: '请输入11位手机号码',
              pattern: /^[1]{1}[0-9]{10}$/,
            },
          ]}
          name='phone'
        >
          <Input placeholder='请输入用户名' clearable />
        </FormItem>
        <FormItem
          label='旧密码'
          rules={[
            {
              required: true,
              message: '旧密码不能为空',
            },
          ]}
          name='oldPassword'
        >
          <Input type='password' placeholder='请输入旧密码' clearable />
        </FormItem>
        <FormItem
          name='newPassword'
          label='新密码'
          rules={[{ required: true, message: '新密码不能为空' }]}
        >
          <Input type='password' placeholder='请输入新密码' clearable />
        </FormItem>
        <FormItem
          name='reNewPassword'
          label='重复新密码'
          rules={[{ required: true, message: '重复新密码不能为空' }]}
        >
          <Input type='password' placeholder='请输入重复新密码' clearable />
        </FormItem>
      </LoginForm>
      <BackGround />
    </>
  )
}
export default Login
