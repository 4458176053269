/*
 * @Author: lirx
 * @Date: 2022-09-10 18:05:57
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-12 09:44:50
 */
import { useTitle } from 'ahooks'
import { Button, Form, Input } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const GoRegister = styled.div`
  font-size: 14px;
  margin-top: 12px;
  span {
    color: var(--adm-color-success);
  }
`

function Login() {
  useTitle('福临福-注册')
  const navigate = useNavigate()
  const onFinish = () => {}
  return (
    <Form
      layout='horizontal'
      onFinish={onFinish}
      footer={
        <>
          <Button block type='submit' color='success' size='large'>
            登录
          </Button>
          <GoRegister>
            已经有账号？
            <span
              onClick={() => {
                navigate('/flf/login', { replace: true })
              }}
            >
              返回登录
            </span>
          </GoRegister>
        </>
      }
    >
      <Form.Item
        label='手机号'
        rules={[
          {
            required: true,
            message: '请输入11位手机号码',
            pattern: /^[1]{1}[0-9]{10}$/,
          },
        ]}
        name='phone'
      >
        <Input placeholder='请输入用户名' clearable />
      </Form.Item>
      <Form.Item
        name='passWord'
        label='密码'
        rules={[{ required: true, message: '密码不能为空' }]}
      >
        <Input placeholder='请输入密码' clearable />
      </Form.Item>
    </Form>
  )
}
export default Login
