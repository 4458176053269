/*
 * @Author: lirx
 * @Date: 2022-09-09 19:26:00
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-11 19:30:15
 */

import { useRoutes } from 'react-router-dom'

import routes from './router/index'

function App() {
  return useRoutes(routes)
}

export default App
