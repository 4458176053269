/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/*
 * @Author: lirx
 * @Date: 2022-09-11 09:54:21
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-15 10:50:59
 */
import { Card, ActionSheet, Dialog } from 'antd-mobile'
import { RightOutline } from 'antd-mobile-icons'
import { ActionSheetShowHandler } from 'antd-mobile/es/components/action-sheet'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { splitOrder, userConfirm } from '../../../api/common'

import storage from '../../../utils/storage'

export type OrderListProps = {
  name: string
  orderId: string
  phone: string
  stylistName: string
  stylistPhone: string
  state: '0' | '1'
  processId: any
  updateTime: string
  isExpire: string
  createTime: string
  id: string
  stateName: string
  adder: string
  hideAction?: boolean
  /**
   * 0未完成 1 完成
   */

  isEnd: 0 | 1
  /**
   * 1确认  0或者null 未确认
   */

  userConfirm: 0 | 1
  logistics: string
  onSplit?: (res: any) => void
}

function OrderList(props: OrderListProps) {
  const handler = useRef<ActionSheetShowHandler>()

  const navigate = useNavigate()
  storage.set('orderInfo', props)
  const username = storage.get<string>('username')
  const userRoleId = storage.get<number>('userRoleId')
  const orderMap = storage.get<any>('orderMap')
  const canManage = !!username
  const customerPhone = storage.get('customerPhone')
  const actions: any = [
    canManage &&
      userRoleId !== 3 && {
        text:
          props.isExpire === '1'
            ? '已过期等待拆单'
            : props.state === '1'
            ? '已拆单'
            : '拆单',
        key: 'split',
        onClick: () => {
          Dialog.confirm({
            title: '温馨提示',
            content: '您确定要拆单吗？',
            onConfirm() {
              splitOrder({
                id: props.id,
              }).then(res => {
                handler.current?.close()
                props.onSplit?.(res)
              })
            },
          })
        },
        disabled: props.state === '1',
      },
    {
      text: '查看详情',
      key: 'detail',
      bold: true,
      onClick: () => {
        storage.set('orderInfo', props)
        navigate('/flf/progress')
        handler.current?.close()
      },
    },
    canManage &&
      userRoleId === 1 && {
        text: '修改订单信息',
        key: 'detail2',
        bold: true,
        onClick: () => {
          storage.set('orderInfo', props)
          navigate('/flf/editorder')
          handler.current?.close()
        },
      },
    ((canManage && userRoleId !== 2) ||
      userRoleId === 2 ||
      (userRoleId === 2 && props.processId > 5)) && {
      text: '修改订单状态',
      key: 'detail3',
      bold: true,
      onClick: () => {
        storage.set('orderInfo', props)
        navigate('/flf/editorderstate')
        handler.current?.close()
      },
      disabled:
        (userRoleId === 2 && props.processId >= 5 && props.processId < 12) ||
        (userRoleId === 3 && props.processId < 5) ||
        (userRoleId === 3 && props.processId >= 12),
    },
    !canManage &&
      props.isEnd === 1 &&
      props.userConfirm !== 1 && {
        text: '完成订单',
        key: 'detail4',
        bold: true,
        onClick: () => {
          storage.set('orderInfo', props)
          Dialog.confirm({
            title: '温馨提示',
            content: '确定要完成订单吗？',
            onConfirm() {
              userConfirm({
                id: props.id,
              }).then(res => {
                props.onSplit?.(res)
                handler.current?.close()
              })
            },
          })
        },
      },
  ]

  console.log(actions)
  return (
    <Card
      title={
        <div style={{ fontWeight: 'normal', marginLeft: 4 }}>{props.name}</div>
      }
      extra={
        props.hideAction ? null : (
          <span
            onClick={() => {
              handler.current = ActionSheet.show({
                actions: actions.filter(Boolean),
                extra: '请选择你要进行的操作',
                cancelText: '取消',
              })
            }}
          >
            更多操作
            <RightOutline />
          </span>
        )
      }
      style={{
        borderRadius: '16px',
        margin: 12,
        position: 'relative',
        boxShadow: '0px 4px 16px 0px rgb(223 225 230)',
        border:
          !customerPhone && userRoleId !== 3 && props.isExpire === '1'
            ? '1px solid red'
            : username
            ? ''
            : '',
      }}
    >
      <div>订单号：{props.orderId}</div>
      <div>手机号：{props.phone}</div>
      <div>设计师名称：{props.stylistName}</div>
      <div>设计师电话：{props.stylistPhone}</div>
      {!props.hideAction && (
        <div>
          当前状态：
          {props.stateName ||
            orderMap?.[props.processId - 1]?.name ||
            '等待拆单'}
        </div>
      )}
      <div>入库时间：{props.createTime}</div>
      <div>更新时间：{props.updateTime}</div>
      <div>地址：{props.adder}</div>
      {props.logistics && <div>物流单号：{props.logistics}</div>}
    </Card>
  )
}

export default OrderList
