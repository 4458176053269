/*
 * @Author: lirx
 * @Date: 2021-07-12 11:24:07
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-11 13:39:58
 */
import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, embed,
	figure, figcaption, footer, header, hgroup,
	menu, nav, output, ruby, section, summary,
	time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
    user-select: none;
    box-sizing: border-box;
	}
	/* HTML5 display-role reset for older browsers */
	article, aside, details, figcaption, figure,
	footer, header, hgroup, menu, nav, section {
		display: block;
	}
	body {
		line-height:22px;
    font-size: 12px;

	}
	html, body{
    background: #ffffff;
    font-family: var(---family-normal);
    /* height:100%; */
	}
  body.body-overflow-hidden {
    overflow: hidden !important;
  }
	ol, ul {
		list-style: none;
	}
  div{
    box-sizing: border-box;
    user-select: none;
  }
	blockquote, q {
		quotes: none;
	}
	blockquote:before, blockquote:after,
	q:before, q:after {
		content: '';
		content: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	a{
		text-decoration: none;
	}
  p{
    line-height: 22px;
  }
  #root{
    /* height:100% */
    overflow-x: hidden;
  }
  --color-theme: #ffd630;  //主题色
  --color-default: #293040;
  --color-title: #293040;
  --color-head: #333;
  --color-content: #717787;
  --color-description: #a1a6b3; //提示性文本
  --color-border: #dfe1e6; //边框颜色
  --color-disabled: #dfe1e6; //不可用状态颜色
  --color-link: #477bef; //链接文本
  --color-highlight: #ff5712; //橙色高亮提示
  --color-warn: #f33920; //警告红色
  --color-btn-sure: #ffd630; //确认按钮
  --color-btn-cancel: transparent; //取消按钮
  --color-gradient-start: #ffd630; //渐变色起始
  --color-gradient-end: #fdc600; //渐变色结束
  --color-main-background: #f5f5f9; //页面背景
  // 以下颜色根据实际情况使用，没有确切含义
  --color-white: #fff;
  --color-black: #293040;
  --color-graw1: #717787;
  --color-graw2: #a1a6b3;
  --color-graw3: #dfe1e6;
  --color-blue: #477bef;
  --color-orange: #ff5712;
  --color-red: #f33920;
  --color-yellow: #ffd630;

  --border-single: 1px solid var(--color-border);  //正常边框
  --border-bold: 2px;    //粗边框
  --border-radius: 4px;  //圆角边框

  --h-head: 44px; //页面顶部高度
  --h-head-big: 52px; //页面顶部高度(适用于半屏弹层)
  --h-foot: 52px;  //页面底部高度
  --h-item: 56px; //列表行高
  --h-input: 22px; //输入框高度
  --h-btn: 52px; //通用按钮高度
  --h-line: 22px; //普通段落行高
  --h-item-detal: 40px;  //卡交易明细行高

  --dis-left-right: 16px; //默认左右边距
  --dis-top: 12px; //默认顶部距离
  --dis-title-both: 66px; //默认view的标题距离两边的距离
  --dis-text: 10px; // 文本间隔
  --dis-top-1: 20px;  //较高间距
  --dis-title: 50px;  //特殊页面标题和顶部的间距

  --radius-half-page:8px;//所以半屏弹出层顶部的圆角

  --family-normal: "BlinkMacSystemFont", "Helvetica Neue", "PingFang SC", "Microsoft YaHei", "Source Han Sans SC", "Noto Sans CJK SC", "WenQuanYi Micro Hei", "sans-serif";
  --family-password: "Microsoft YaHei", "sans-serif";
  --family-number: "DINPro-medium";

  --font-default: 16px;
  --font-title: 18px;
  --font-big-title: 24px;
  --font-big-number: 36px;
  --font-content: 16px;

  --font-text: 14px;
  --font-description: 12px;
  --font-12: 12px;
  --font-14: 14px;
  --font-16: 16px;
  --font-18: 18px;
  --font-20: 20px;
  --font-22: 22px;
  --font-24: 24px;
  --font-26: 26px;
  --font-28: 28px;
  --font-30: 30px;
  --font-32: 32px;
  --font-36: 36px;
  --font-40: 40px;

  --icon-little: 30px;
  --icon-size: 32px;  //图标大小
  --icon-small: 20px;  //小图标

  --z-header: 100;  //顶部标题栏层级
  --z-footer: 100;  //底部栏层级
  --z-content: 1;   //中间内容层级
  --z-view: 1;      //view页面层级
  --z-modal: 200;   //弹出层页面层级
  --z-message: 300;  //短信验证码
  --z-password: 300; //密码键盘，这里要注意的是，三方密码键盘提供的z-index是99999,现已改为888
  --z-keyboard: 888;
  --z-popup: 999;   //弹出提示框层级

  --animation-duration-base:.3s;

`
