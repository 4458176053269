/*
 * @Author: lirx
 * @Date: 2022-09-10 14:02:03
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-14 21:08:56
 */
import { useTitle } from 'ahooks'
import { Steps, Divider } from 'antd-mobile'
import { CheckCircleFill } from 'antd-mobile-icons'
import { off } from 'process'
import storage from '../../utils/storage'
import OrderList, { OrderListProps } from '../component/order-list'

const { Step } = Steps
function Progress() {
  const orderMaps = storage.get<any>('orderMap')
  const { processId, ...props } = storage.get<OrderListProps>('orderInfo')
  useTitle(`${props.name}进度详情`)
  const orderMap = {} as any
  orderMaps.forEach((item: any) => {
    orderMap[item.id] = item.name
  })
  if (props.userConfirm === 1) {
    orderMap['99'] = '客户确认'
  }
  function getDes(item: any): string {
    // eslint-disable-next-line eqeqeq
    if (processId === 12 && item == processId) {
      return `商品已经发货，物流单号：${item.logistics}`
    }
    // eslint-disable-next-line eqeqeq
    if (item == '99') {
      return '客户确认收货'
    }
    return `商品${item <= processId ? '已经' : '暂未'}${
      (orderMap as any)[item]
    }`
  }
  return (
    <>
      <OrderList {...props} processId={processId} hideAction />
      <Divider>进度详情</Divider>
      <Steps
        direction='vertical'
        current={orderMap['99'] ? 99 : Number(processId) - 1}
        style={{
          '--title-font-size': '17px',
          '--description-font-size': '15px',
          '--indicator-margin-right': '12px',
          '--icon-size': '22px',
        }}
      >
        {Object.keys(orderMap).map(item => (
          <Step
            key={String(item)}
            title={(orderMap as any)[item]}
            description={getDes(item)}
            icon={
              item <= processId ? (
                <CheckCircleFill color='#76c6b8' />
              ) : (
                <CheckCircleFill />
              )
            }
          />
        ))}
      </Steps>
    </>
  )
}

export default Progress
