/*
 * @Author: lirx
 * @Date: 2022-09-10 14:02:03
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-11 18:11:56
 */
import { Dialog } from 'antd-mobile'
import { useTitle } from 'ahooks'
import { useNavigate } from 'react-router-dom'
import { addOrder } from '../../api/common'
import OrderInfo from '../component/order-info'

export default function () {
  useTitle('订单录入')
  const navigate = useNavigate()
  const onFinish = (values: any) => {
    addOrder(values).then(() => {
      Dialog.alert({
        title: '温馨提示',
        content: '录入成功',
        onConfirm() {
          navigate('/flf/orderlist')
        },
      })
    })
  }
  return <OrderInfo onFinish={onFinish} btnText='录入订单' />
}
