/*
 * @Author: lirx
 * @Date: 2022-09-11 09:59:26
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-14 19:17:56
 */
import { useDebounceEffect, useScroll, useTitle } from 'ahooks'
import {
  ActionSheet,
  FloatingBubble,
  InfiniteScroll,
  Input,
  NoticeBar,
  Space,
} from 'antd-mobile'
import { AddOutline, HeartOutline, UpCircleOutline } from 'antd-mobile-icons'
import {
  Action,
  ActionSheetShowHandler,
} from 'antd-mobile/es/components/action-sheet'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { queryAllOrder, queryByPhone } from '../../api/common'
import storage from '../../utils/storage'
import OrderList from '../component/order-list'
import searchIcon from './img/search.png'
import trangle from './img/trangle.png'

const Float = styled(FloatingBubble)`
  .adm-floating-bubble-button {
    background: #76c6b8;
  }
`

const SearchGroup = styled.div`
  padding: 16px;
  display: flex;
  background: #fff;
  margin-top: 2px;

  .input {
    width: 260px;
    height: 30px;
    border-radius: 100px;
    padding-left: 10px;
    line-height: 30px;
    color: rgba(161, 166, 179, 100);
    font-size: 14px;
    background-color: rgba(245, 245, 249, 1);
    position: relative;
    .search-input-icon {
      width: 14px;
      height: 14px;
      position: absolute;
      top: 8px;
    }
    .twitter-search-input {
      height: 30px;
      position: relative;
      left: 18px;
      .adm-input-clear {
        position: relative;
        right: 20px;
      }
    }
  }
  .trangle-img {
    width: 10px;
    height: 10px;
  }
  .btn {
    width: 90px;
    height: 30px;
    border-radius: 100px;
    background-color: rgba(223, 225, 229, 0.37);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 17px;
    margin-left: 13px;
  }
`

function ScrollToTop() {
  const scroll = useScroll(document)
  return (scroll?.top as any) > window.innerHeight ? (
    <Float
      axis='xy'
      magnetic='x'
      style={{
        '--initial-position-bottom': '88px',
        '--initial-position-right': '24px',
        '--edge-distance': '24px',
      }}
    >
      <UpCircleOutline
        onClick={() => {
          window.scrollTo(0, 0)
        }}
        fontSize={32}
      />
    </Float>
  ) : null
}
function clearAllCookie() {
  // eslint-disable-next-line no-useless-escape
  const keys = document.cookie.match(/[^ =;]+(?=\=)/g)
  if (keys) {
    // eslint-disable-next-line no-plusplus
    for (let i = keys.length; i--; )
      document.cookie = `${keys[i]}=0;expires=${new Date(0).toUTCString()}`
  }
}
export default function Index() {
  const [orderList, setOrderList] = useState([])
  const method = storage.get('customerPhone') ? queryByPhone : queryAllOrder
  useTitle('订单列表')
  const navigate = useNavigate()
  const [hasMore, setHasMore] = useState(true)
  const handler = useRef<ActionSheetShowHandler>()
  const [phone, setPhone] = useState<any>()
  const [pageNumber, setpageNumber] = useState(1)
  const [isExpire, setIsExpire] = useState<any>()
  const username = storage.get<string | undefined>('username')
  const userRoleId = storage.get<number | undefined>('userRoleId')
  async function loadMore() {
    const append: any = await method({
      'phone': storage.get('customerPhone'),
      'pageNumber': pageNumber,
      'pageSize': 10,
    })
    setOrderList(val => {
      if (!append.aaData) {
        setHasMore(false)
        return append
      }
      let length
      if (pageNumber === 1) {
        length = append.aaData.length
        setHasMore(Number(append.itotalRecords) > length)
        return append.aaData
      }
      length = [...val, ...append.aaData].length
      setHasMore(Number(append.itotalRecords) > length)
      return [...val, ...append.aaData] as any
    })
    setpageNumber(prev => prev + 1)
  }
  const actions: Action[] = [
    {
      text: '全部',
      key: 'split',
      onClick: () => {
        setIsExpire('')
        handler.current?.close()
      },
    },
    {
      text: '未过期',
      key: 'detail1',
      bold: true,
      onClick: () => {
        setIsExpire('0')
        handler.current?.close()
      },
    },
    {
      text: '已过期',
      key: 'detail',
      bold: true,
      onClick: () => {
        setIsExpire('1')
        handler.current?.close()
      },
    },
  ]
  useDebounceEffect(
    () => {
      if (!username) return
      if (phone || phone === '' || isExpire || isExpire === '') {
        method({
          'phone': storage.get('customerPhone'),
          'pageNumber': 1,
          'pageSize': 10,
          content: phone,
          isExpire,
        }).then(res => {
          setOrderList(() => {
            const { length } = [...res.aaData]
            setHasMore(Number(res.itotalRecords) > length)
            setpageNumber(1)
            return [...res.aaData] as any
          })
        })
      }
    },
    [phone, isExpire],
    {
      wait: 300,
    }
  )
  return (
    <>
      {!storage.get('customerPhone') && (
        <NoticeBar
          extra={
            <Space style={{ '--gap': '12px' }}>
              <span
                onClick={() => {
                  clearAllCookie()
                  storage.remove(Object.keys(sessionStorage))
                  // eslint-disable-next-line no-restricted-globals
                  location.replace(`${location.origin}/index.html#/flf/login`)
                }}
              >
                退出登录
              </span>
            </Space>
          }
          icon={<HeartOutline />}
          content={`欢迎你：${username}`}
          color='alert'
        />
      )}
      {!storage.get('customerPhone') && (
        <SearchGroup>
          <div className='input'>
            <img src={searchIcon} className='search-input-icon' />
            <Input
              className='twitter-search-input'
              placeholder='模糊搜索手机号、订单号'
              onChange={setPhone}
              value={phone}
              onlyShowClearWhenFocus={false}
              clearable
            />
          </div>
          <div
            className='btn'
            onClick={() => {
              handler.current = ActionSheet.show({
                actions,
                extra: '请选择你要进行的操作',
                cancelText: '取消',
              })
            }}
          >
            {isExpire === '0' ? '未过期' : isExpire === '1' ? '已过期' : '全部'}
            <img src={trangle} className='trangle-img' />
          </div>
        </SearchGroup>
      )}
      {orderList.map((item: any) => (
        <OrderList
          {...item}
          key={item.id}
          onSplit={newItem => {
            setOrderList((prev: any) =>
              prev.map((t: { id: any }) => (t.id === newItem.id ? newItem : t))
            )
          }}
        />
      ))}
      {username && userRoleId === 1 ? (
        <Float
          axis='xy'
          magnetic='x'
          style={{
            '--initial-position-top': '64px',
            '--initial-position-right': '24px',
            '--edge-distance': '24px',
          }}
        >
          <AddOutline
            onClick={() => {
              navigate('/flf/inputinfo')
            }}
            fontSize={32}
          />
        </Float>
      ) : null}
      <ScrollToTop />
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
    </>
  )
}
