/*
 * @Author: lirx
 * @Date: 2022-09-10 14:02:03
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-14 19:48:26
 */
import { Form, Input, Button } from 'antd-mobile'
import { ReactNode, useEffect } from 'react'

type Prop = {
  onFinish: (values: any) => void
  showQueryCode?: boolean
  btnText: string
  values?: any
  disableOrderInfo?: boolean
  disableOrder?: boolean
  children?: ReactNode
  showLogistics?: boolean
}

function OrderInfo(props: Prop) {
  const [form] = Form.useForm()
  const { disableOrderInfo, disableOrder } = props
  useEffect(() => {
    if (props.values)
      form.setFieldsValue({
        ...props.values,
        logistics: props.values.logistics ?? '',
        processId: props.values.processId
          ? [props.values.processId]
          : undefined,
      })
  }, [form, props.values])
  return (
    <Form
      onFinish={props.onFinish}
      form={form}
      footer={
        <Button block type='submit' color='success' size='large'>
          {props.btnText}
        </Button>
      }
    >
      <Form.Item
        name='orderId'
        label='订单号'
        rules={[{ required: true }]}
        disabled={disableOrderInfo || disableOrder}
      >
        <Input placeholder='请输入订单号' />
      </Form.Item>
      <Form.Item
        name='name'
        label='姓名'
        rules={[{ required: true }]}
        disabled={disableOrderInfo}
      >
        <Input placeholder='请输入姓名' maxLength={10} />
      </Form.Item>
      <Form.Item
        name='phone'
        label='电话号码'
        rules={[
          {
            required: true,
            message: '请输入11位手机号码',
            pattern: /^[1]{1}[0-9]{10}$/,
          },
        ]}
        disabled={disableOrderInfo}
      >
        <Input placeholder='请输入电话号码' />
      </Form.Item>
      <Form.Item
        name='stylistName'
        label='设计师姓名'
        rules={[{ required: true }]}
        disabled={disableOrderInfo}
      >
        <Input maxLength={10} placeholder='请输入设计师姓名' />
      </Form.Item>
      <Form.Item
        name='stylistPhone'
        label='设计师电话号码'
        rules={[
          {
            required: true,
            message: '请输入11位手机号码',
            pattern: /^[1]{1}[0-9]{10}$/,
          },
        ]}
        disabled={disableOrderInfo}
      >
        <Input placeholder='请输入设计师电话号码' />
      </Form.Item>
      <Form.Item
        name='adder'
        label='客户地址'
        rules={[{ required: true }]}
        disabled={disableOrderInfo}
      >
        <Input maxLength={50} placeholder='请输入客户地址' />
      </Form.Item>
      {props.showQueryCode && (
        <Form.Item
          name='queryCode'
          label='查询码'
          rules={[{ required: true }]}
          disabled={disableOrderInfo}
        >
          <Input placeholder='请输入查询码' />
        </Form.Item>
      )}
      {props?.values?.processId >= 12 && props.showLogistics && (
        <Form.Item
          name='logistics'
          label='物流单号'
          rules={[{ required: true }]}
        >
          <Input maxLength={50} placeholder='请输入物流单号' />
        </Form.Item>
      )}
      {props.children}
    </Form>
  )
}
OrderInfo.defaultProps = {
  showQueryCode: false,
  values: null,
  disableOrderInfo: false,
  disableOrder: false,
  children: null,
  showLogistics: false,
}
export default OrderInfo
